import styled from "styled-components";
import BaseButton from './BaseButon';

export const SquareButton = styled(BaseButton)`
  padding: 1.6rem 2.4rem;
  border-width: 1px;
  border-style: solid;
  border-color: #FDB819;

  transition: all .2s;

  :hover {
    background-color: #fefdfa;
  }
`;