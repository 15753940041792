import React from "react";
import { graphql, useStaticQuery } from 'gatsby';

import MainButton from "../../UI/Buttons/MainButton";
import IconInstagram from "../../../assets/icons/instagram.svg";
import IconFacebook from "../../../assets/icons/facebook.svg";
import IconVk from "../../../assets/icons/vk.svg";
import BackgroundImage from './BackgroundImage';

import { MainSection, MainContainer, MainSocial } from './styles';

const HomeMain = ({title, description, social}) => {
  return (
    <MainSection>
      <BackgroundImage />
      <MainContainer className="container">
        <div className="content">
          <h1>{ title }</h1>
          <p>{ description }</p>
          <div className="action">
            <MainButton />
          </div>
        </div>
        {/* Social */}
        <MainSocial>
          {/* eslint-disable-next-line */}
          <a href={social.instagram} target="_blank" rel="nofollow">
            <IconInstagram width="30" height="30" fill="#fafafa" />
          </a>
          {/* eslint-disable-next-line */}
          <a href={social.facebook} target="_blank" rel="nofollow">
            <IconFacebook width="30" height="30" fill="#fafafa" />
          </a>
          {/* eslint-disable-next-line */}
          <a href={social.vk} target="_blank" rel="nofollow">
            <IconVk width="30" height="30" fill="#fafafa" />
          </a>
        </MainSocial>
      </MainContainer>
    </MainSection>
  );
}

export default HomeMain;