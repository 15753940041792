import styled from "styled-components";
import { Link } from "gatsby";

export const StyledSection = styled.section`
  padding: 10rem 0;
`;

export const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  grid-column-gap: 3rem;
  .section-heading {
    grid-column: span 4;
  }

  @media (max-width: 640px) {
    display: none;
  }
`;

export const ServicesContainerMobile = styled.div`
  @media not all and (max-width: 640px) {
    display: none;
  }
`;

export const StyledCard = styled.div`
  border-radius: .8rem;
  height: ${ props => props.mobile ? `48rem` : `58rem` };
  width: 34rem;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  position: relative;
  margin: 0 auto;

  &:hover {
    .caption {
       transform: translateY(0);
    }
  }
`;

export const ServiceCaption = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${ props => props.mobile ? `rgba(26, 32, 44, .6)` : `rgba(26, 32, 44, .9)` };
  transform: ${ props => props.mobile ? `translateY(0)` : `translateY(80%)` };
  transition: all .5s;
  color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > * {
    padding: 2rem;
  }

  .title {
    margin: 0;
    font-size: 1.8rem;
    text-align: center;
  }
  .action {
    align-self: center;
  }
`;

export const HomeServiceCardLink = styled(Link)`
  padding: .8rem 1.4rem;
  border: 1px solid #fafafa;
`;