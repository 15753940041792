import React from "react";

import {StyledSection, InquiryContainer} from './styles';
import HomeInquiryForm from "./HomeInquiryForm";

const HomeInquiry = ({ text, models }) => {
  return (
    <StyledSection>
      <InquiryContainer className="container">
        <h2>Помощь в подборе запчастей</h2>
        <p className="content">{ text }</p>
        <HomeInquiryForm className="form" models={ models } />
      </InquiryContainer>
    </StyledSection>
  );
};

export default HomeInquiry;