import styled from "styled-components";

const SectionHeading = styled.h2`
  font-size: 2.2rem;
  margin-bottom: 3.5rem;
  &:after {
    margin-top: .2rem;
    display: block;
    content: "";
    width: 5vw;
    height: 3px;
    border-radius: 8px;
    background-color: #FDB819;
  }
`;

export default SectionHeading;