import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const BackgroundImage = () => {

  const data = useStaticQuery(graphql`
    query COVER_IMAGE_QUERY {
      file(name: {eq: "cover"}) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Img 
      css={{ top: 0, left: 0, right: 0, bottom: 0 }}
      style={{
        position: `absolute`,
        filter: `opacity(25%)`,
        zIndex: 1,
      }}
      fluid={ data.file.childImageSharp.fluid }
    />
  );
};

export default BackgroundImage;