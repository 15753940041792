import assert from 'assert'
import Factory from '../../abstracts/Factory'
import {ServiceItemCard} from '../../entities/ServiceItemCard'
import { MarkdownRemark } from '../../types/graphql-types'
import { GatsbyImageProps } from 'gatsby-image'

export default class ServiceItemCardFactory extends Factory<any, ServiceItemCard> {
    public create(input: MarkdownRemark): ServiceItemCard {
        assert(typeof input?.frontmatter?.slug === 'string')

        return {
            slug: input?.frontmatter?.slug || '',
            title: input?.frontmatter?.title || '',
            description: input.frontmatter?.description || '',
            image: input.frontmatter?.image
        }
    }
    
    public static replaceImage(service: ServiceItemCard, image: GatsbyImageProps): ServiceItemCard {
        return {
            ...service,
            image
        } 
    }
}