import React from "react";
import Img from "gatsby-image";

import { StyledCard, ServiceCaption, HomeServiceCardLink } from "./styles";

const HomeServiceCard = ({ title, description, slug, image, mobile }) => {
  return (
    <StyledCard mobile={ mobile }>
      <Img fixed={ image.fixed } />
      <ServiceCaption className="caption" mobile={ mobile }>
        <h3 className="title">{ title }</h3>
        <div className="description">{ description }</div>
        <div className="action">
          <HomeServiceCardLink to={ `/${slug}` }>Перейти в раздел &rarr;</HomeServiceCardLink>
        </div>
      </ServiceCaption>
    </StyledCard>
  );
};

export default HomeServiceCard;