export default class HomeRepository {
  static addImagesToCategories(categories, imagesData) {
    for (const category of categories) {
      const image = imagesData.find(item => item.name === category.image);
      if (image) {
        category.image = image.childImageSharp;
      }
    }
    return categories;
  }

  static addImagesToServices(services, imagesData) {
    for (const service of services) {
      const image = imagesData.find(item => item.name === service.image);
      if (image) {
        service.image = image.childImageSharp;
      }
    }
    return services;
  }

  static addCountAndMinPriceToCategories(categories, partsItemsData) {
    partsItemsData.forEach(({nodes, totalCount, fieldValue}) => {
      // Находим минимальную цену для каждой категории
      const sortedPartsItemsData = nodes.filter(item => Boolean(Number(item.price)))
                                        .sort((a, b) => Number(a.price) - Number(b.price));
      const minPrice = sortedPartsItemsData[0].price;
      // Добавляем минимальную цену и общее кол-во к каждой категории
      const selectedCategory = categories.find(category => category.shortName === fieldValue);
      if (selectedCategory) {
        const index = categories.indexOf(selectedCategory);
        categories[index] = {
          ...categories[index],
          count: totalCount,
          minPrice
        };
      }
    });
    return categories;
  }

}
