import styled from "styled-components";
import { Container } from '../../styles/Container';

export const HomeCategoriesSection = styled.section`
  padding: 6rem 0 12rem 0;
  background-color: #f6f6f6;
`;

export const HomeCategoriesContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(36rem, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 3rem;
  h2 {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
  }
  @media (max-width: 640px) {
    display: none;
  }
`;

export const HomeCategoriesContainerMobile = styled(Container)`
  h2 {
    margin-bottom: 5rem;
  }
  @media not all and (max-width: 640px) {
    display: none;
  }
`;

export const HomeCategoriesAction = styled.div`
  grid-column: 1 / -1;
  justify-self: end;
  margin-top: 2.4rem;
`;

export const StyledCategoryCard = styled.div`
 box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
              0 4px 6px -2px rgba(0, 0, 0, 0.05);
  background: #fafafa;
  border-radius: .8rem;
  padding-top: 2.5rem;
  
  a {
    text-decoration: none;
    display: grid;
    color: currentColor;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 2.5rem;
  }

  :hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  
  .image {
    grid-column: span 2;
    justify-self: center;
    background-color: ${ props => props.background };

    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
  }
  .description {
    padding: 0 2rem;
    height: 8rem;
    grid-column: span 2;
    color: #4e4e4e;
  }
  .title {
    grid-column: span 2;
    margin: 0;
    border-radius: 0 0 .8rem .8rem;
    text-align: center;
    cursor: pointer;

    border-top: 1px solid #dadada;
    color: #3f5359;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    font-weight: 600;
    padding: 2rem 1.5rem;
  }
  .item {
    display: flex;
    align-items: center;
    padding-left: 2rem;
    &__icon {
      margin-right: 1vw;
    }
    &__desc {
      color: #B4B4B4;
    }
  }
  .value {
    align-self: center;
    justify-self: end;
    padding-right: 2rem;
  }
`;