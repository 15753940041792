import React from 'react';

import FormHandler from '../../../features/Email/FormHandler';

import { StyledForm } from './styles';
import { SimpleButton } from "../../styles/Button";

const formHandler = new FormHandler();

const HomeInquiryForm = ({ models }) => {
  const [stateData, setStateData] = React.useState({
    subject: "Помощь в подборе запчастей",
    username: "",
    contact: "",
    model: "",
    message: ""
  });

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const { success } = await formHandler.postFormData(stateData);
    if (success) {
      // Очищаем данные формы
      setStateData({
        username: "",
        contact: "",
        model: "",
        message: "",
      });
    }
  };

  return (
    <StyledForm onSubmit={ onFormSubmit } autoComplete="off">
      <label htmlFor="username">Имя</label>
      <input
        id="username"
        name="username"
        type="text"
        placeholder="Имя"
        required
        value={stateData.username}
        onChange={(e) => setStateData({
          ...stateData,
          username: e.target.value,
        })}
       />
      <label htmlFor="contact">Телефон или email</label>
      <input
        id="contact"
        name="contact"
        type="text"
        placeholder="email@mail.ru"
        required
        value={stateData.contact}
        onChange={(e) => {
          setStateData({
            ...stateData,
            contact: e.target.value,
          });
        }}
      />
      <label htmlFor="model">Модель автомобиля</label>
      <select
        id="model"
        name="model"
        value={stateData.model}
        onChange={(e) => {
          setStateData({
            ...stateData,
            model: e.target.value,
          });
        }}
      >
        <option value="">Выберете модель автомобиля из списка</option>
        { models.map(model => <option key={model.slug} value={model.slug}>{model.title}</option>)}
        <option value="other">Другое</option>
      </select>
      <label htmlFor="message">Сообщение</label>
      <textarea
        id="message"
        name="message"
        cols="30"
        rows="10"
        placeholder="Дополнительные сведения (модификация автомобиля, год выпуска, VIN номер, желаемая степерь износа и т.д.)"
        value={stateData.message}
        onChange={(e) => {
          setStateData({
            ...stateData,
            message: e.target.value,
          });
        }}
      />
      <SimpleButton type="submit">Отправить</SimpleButton>
    </StyledForm>
  );
};

export default HomeInquiryForm;