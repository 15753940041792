import styled from "styled-components";

export const StyledSection = styled.section`
padding-bottom: 7.5rem;
`;

export const StyledCard = styled.div`
:not(:last-child) {
  margin-bottom: 2rem;
}
.title {
  /* font-weight: 600; */
  font-size: 1.8rem;
  display: inline-block;
  margin-bottom: .5rem;
  position: relative;

  :after {
    display: block;
    content: '';
    width: 100%;
    height: 40%;
    position: absolute;
    top: 55%;
    left: 0;
    background-color: #C2E9FF;
    z-index: -1;
  }
}
.date {
  color: #a4a4a4;
  margin-top: 1.2rem;
}
`;