import React from "react";
import styled from "styled-components";

const StyledSubheader = styled.h2`
  &:after {
    margin-top: .2rem;
    display: block;
    content: "";
    width: 5vw;
    height: 3px;
    border-radius: 8px;
    background-color: #FDB819;
  }
`;

const Subheader = ({children}) => (
  <StyledSubheader>
    { children }
  </StyledSubheader>
);

export default Subheader;