import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

const StyledButton = styled.div`
  display: inline-block;
  text-align: center;
  position: relative;
  padding: 18px 60px;
  border: 2px solid #fff;
  background-color: rgba(255,255,255, .3);
  cursor: pointer;

  @media (max-width: 480px) {
    padding: 1.4rem 4rem;
  }

  &:hover {
    border: none;
    padding: 20px 62px;
    .line-1 {
      -webkit-animation: move1 1500ms infinite ease;
              animation: move1 1500ms infinite ease;
    }
    .line-2 {
      -webkit-animation: move2 1500ms infinite ease;
              animation: move2 1500ms infinite ease;
    }
    .line-3 {
      -webkit-animation: move3 1500ms infinite ease;
              animation: move3 1500ms infinite ease;
    }
    .line-4 {
      -webkit-animation: move4 1500ms infinite ease;
              animation: move4 1500ms infinite ease;
    }
  }
  button {
    cursor: pointer;
    background: transparent;
    color: inherit;
    text-decoration: none;
    border: none;
    letter-spacing: 2px;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;

  }
  .line-1 {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    background-color: #fff;
    left: 0;
    bottom: 0;
  }
  .line-2 {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    background-color: #fff;
    left: 0;
    top: 0;
  }
  .line-3 {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    background-color: #fff;
    right: 0;
    top: 0;
  }
  .line-4 {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    background-color: #fff;
    right: 0;
    bottom: 0;
  }
`;


const MainButton = () => (
  <StyledButton onClick={(e) => {
    e.preventDefault();
    navigate("/parts/")
  }}>
    <button>Перейти в каталог
      <span className="line-1"></span>
      <span className="line-2"></span>
      <span className="line-3"></span>
      <span className="line-4"></span>
    </button>
  </StyledButton>
);

export default MainButton;