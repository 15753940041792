import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

import formatPrice from "../../../features/Helpers/formatPrice";
import { trimToLength } from "../../../features/Helpers/normalizeString";

import { StyledCategoryCard } from './styles';
import IconStock from "../../../assets/icons/stock.svg";
import IconRuble from "../../../assets/icons/ruble.svg";

const HomeCategoryCard = ({ image, title, description, background, slug, count, minPrice }) => (
  <StyledCategoryCard background={ background }>
    <Link to={`/${slug}`}>
      <div className="image">
        <Img fixed={ image.fixed } />
      </div>
      <div className="description">{ trimToLength(description, 110) }</div>
      <div className="item">
        <IconStock className="item__icon" width="28" height="28" fill="#607A97" />
        <span className="item__desc">Наличие</span>
      </div>
      <div className="value">более { count } позиций</div>
      <div className="item">
        <IconRuble className="item__icon" width="28" height="28" fill="#607A97" />
        <span className="item__desc">Цена</span>
      </div>
      <div className="value">От { formatPrice(minPrice) }</div>
      <h3 className="title">{ title }</h3>
    </Link>
  </StyledCategoryCard>
);

export default HomeCategoryCard;