import styled from 'styled-components';
import { Container } from '../../styles/Container';

export const MainSection = styled.section`
  height: 90vh;
  position: relative;
  background-color: #000;
  color: #fafafa;
  position: relative;
  @media (max-width: 480px) {
    height: 100vh;
  }
`;

export const MainContainer = styled(Container)`
  position: relative;
  z-index: 10;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr) 60px;
  grid-template-rows: 10vh auto 10vh;
  align-items: center;
  .content {
    grid-row: 2 / 3;
    p {
      white-space: pre-line;
    }
    @media (max-width: 480px) {
      grid-column: 1 / -1;
      font-size: 1.5rem;
    }
  }
  .action {
    margin-top: 5rem;
  }
`;

export const MainSocial = styled.div`
  grid-row: 2 / 3;
  grid-column: 3 / 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(25%);
  a:not(:last-child) {
    margin-bottom: 2rem;
  }
  @media (max-width: 480px) {
    display: none;
  }
`;