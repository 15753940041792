import React from "react";

import { StyledSection, ServicesContainer, ServicesContainerMobile } from './styles';
import SectionHeading from "../styles/SectionHeading";
import HomeServiceCard from "./HomeServiceCard";
import AppSlider from "../../UI/Slider/AppSlider";

const HomeServices = ({ services }) => {
  return (
    <StyledSection>
      <ServicesContainer className="container">
        <SectionHeading className="section-heading">Наши услуги</SectionHeading>
        { services.map(service => (
          <HomeServiceCard
            key={ service.slug }
            title={ service.title }
            description={ service.description }
            slug={ service.slug }
            image={ service.image }
          />
        ))}
      </ServicesContainer>
      {/* Mobile */}
      <ServicesContainerMobile className="container">
        <SectionHeading className="section-heading">Наши услуги</SectionHeading>
          <AppSlider>
            { services.map(service => (
              <HomeServiceCard
                key={ service.slug }
                title={ service.title }
                description={ service.description }
                slug={ service.slug }
                image={ service.image }
                mobile={ true }
              />
            ))}
          </AppSlider>
      </ServicesContainerMobile>
    </StyledSection>
  );
};

export default HomeServices;