import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import HomeRepository from "../features/Home/HomeRepository";
import ServiceItemCardFactory from '../features/ServiceItem/ServiceItemCardFactory'

import HomeMain from "../components/Home/HomeMain";
import HomeCategories from "../components/Home/HomeCatogories";
import HomeInquiry from "../components/Home/HomeInquiry";
import HomeServices from "../components/Home/HomeServices";
import HomeNews from "../components/Home/HomeNews";

const IndexPage = ({ pageContext, data: {categoryImages, serviceImages, items} }) => {

  let { mainTitle, mainDescription, social, formText, formModels, services, categories } = pageContext;
  // Находим количество единиц и минимальную стоимость для каждой категории
  const updatedCategories = HomeRepository.addCountAndMinPriceToCategories(categories, items.group);
  // Заменяем изображение для каждой карточки услуги на GatsbyImage
  let normalizedServices = HomeRepository.addImagesToServices(services, serviceImages.nodes);
  // Заменяем изображение для каждой карточки категории на GatsbyImage
  let normalizedCategories = HomeRepository.addImagesToCategories(updatedCategories, categoryImages.nodes);

  return (
    <Layout home={ true }>
      <SEO title="landcruiserparts.ru" />
      <HomeMain
        title={ mainTitle }
        description={ mainDescription }
        social = { social }
      />
      <HomeCategories
        categories={ normalizedCategories }
      />
      <HomeInquiry
        text={ formText }
        models={ formModels }
      />
      <HomeServices services={ normalizedServices } />
      {/* <HomeNews
        newsItems={ newsItems }
      /> */}
    </Layout>
  )
};

export const query = graphql`
query PRICE_ITEMS_COUNT_AND_MIN_PRICE_QUERY($categoryTitles: [String]!, $serviceImages: [String]!, $categoryImages: [String]!) {
  items:allPricelistXlsxCatalog(filter: {category: {in: $categoryTitles}}) {
    group(field: category) {
      nodes {
        price
      }
      totalCount
      fieldValue
    }
  }
  serviceImages:allFile(filter: {dir: {regex: "/cdn/services/"}, name: {in: $serviceImages}}) {
    nodes {
      name
      childImageSharp {
        fixed(toFormat: WEBP, width: 340, height: 580) {
          aspectRatio
          height
          src
          srcSet
          width
        }
      }
    }
  }
  categoryImages:allFile(filter: {dir: {regex: "/cdn/categories/"}, name: {in: $categoryImages}}) {
    nodes {
      name
      childImageSharp {
        fixed(background: "#EFF6E8", height: 65, width: 65, cropFocus: CENTER toFormat: WEBP) {
          aspectRatio
          height
          src
          srcSet
          width
        }
      }
    }
  }
}
`;

export default IndexPage;
