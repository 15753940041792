import React from "react";

import Subheader from "../../UI/Typography/Subheader";
import HomeCategoryCard from "./HomeCategoryCard";
import AppSlider from "../../UI/Slider/AppSlider";
import { SquareButton } from "../../UI/Buttons/SquareButton";

import { HomeCategoriesSection, HomeCategoriesContainer, HomeCategoriesContainerMobile, HomeCategoriesAction } from './styles';

const backgrounds = ['#EFF6E8', '#EDF7FB', '#FEF1EE', '#FFF6E9', '#E9EAFF', '#D1FCEF'];

const HomeCategories = ({ categories }) => {
  return (
    <HomeCategoriesSection>
      <HomeCategoriesContainer>
        <Subheader>Популярные категории</Subheader>
          {categories.map((category, index) => (
            <HomeCategoryCard
              key={ category.slug }
              image={ category.image }
              title={ category.shortName }
              description={ category.description }
              slug={ category.slug }
              background={ backgrounds[index] }
              count={ category.count }
              minPrice={ category.minPrice }
            />
          ))}
        <HomeCategoriesAction>
          <SquareButton to="/categories">Все категории &rarr;</SquareButton>
        </HomeCategoriesAction>
      </HomeCategoriesContainer>
      {/* Mobile */}
      <HomeCategoriesContainerMobile>
        <Subheader className="subheader">Популярные категории</Subheader>
        <AppSlider>
          {categories.map((category, index) => (
            <HomeCategoryCard
              key={ category.slug }
              image={ category.image }
              title={ category.title }
              description={ category.description }
              slug={ category.slug }
              background={ backgrounds[index] }
            />
          ))}
        </AppSlider>
      </HomeCategoriesContainerMobile>
    </HomeCategoriesSection>
  )
};

export default HomeCategories;