import styled from 'styled-components';

export const StyledSection = styled.section`
  background-image: 
    linear-gradient(
      90deg,
      rgba(20, 20, 30, 0.8) 35%,
      rgba(27, 64, 71, 0.3) 100%),
      url('/home-inquiry-bg.jpg');
  background-size: contain;
  color: #fafafa;
  margin-bottom: 5rem;
`;

export const InquiryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5rem;
  padding: 0 1vw;

  h2 {
    grid-column: 1 / -1;
    text-align: center;
  }
  .content {
    grid-row: 2 / 4;
    grid-column: 1 / 2;
    align-self: center;
    text-align: justify;

    font-weight: 600;
  }
  .form {
    grid-row: span 3;
    grid-column: 2 / 3;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    h2,
    .content,
    .form {
      grid-column: 1 / 2;
    }
  }
`;

export const StyledForm = styled.form`
  color: #1a202c;
  background-color: #fafafa;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: .8rem;
  padding: 3rem;
  transform: translateY(15%);
  display: flex;
  flex-direction: column;
  input,
  textarea,
  select {
      padding: 1rem;
      border-radius: .5rem;
      border: 1px solid #eee;
  }
  input,
  select {
      margin-bottom: 1.5rem;
  }
  select {
      background-color: #fff;
  }
  button {
    margin-top: 2rem;
    align-self: flex-end;
  }
  @media (max-width: 1024px) {
    max-width: 65vw;
    width: 100%;
    margin: 0 auto;
}
@media (max-width: 640px) {
  max-width: 80vw;
}
`;